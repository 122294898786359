.mainContainer {
  display: flex;
  flex-direction: column;
  width: 18rem;
  font-size: 1.15rem;
  font-weight: 300;
  position: fixed;
  z-index: 10;
  left: -15rem;
  top: 0;
  transition: all ease-in-out 0.5s;
  height: 100vh;
  background: url('https://mdbootstrap.com/img/Photos/Others/sidenav1.jpg');
}

.mainContainer::after {
  background-color: rgba(0, 0, 0, 0.807);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  z-index: -2;
  width: 100%;
}

.mainContainer:hover {
  left: 0;
}

.mainContainer a:link {
  color: transparent;
}

/* visited link */
.mainContainer a:visited {
  color: transparent;
}

/* mouse over link */
.mainContainer a:hover {
  color: transparent;
}

/* selected link */
.mainContainer a:active {
  color: transparent;
}

.containerLogo {
  width: 100%;
  border-bottom: 1px solid rgba(194, 194, 194, 0.21);
}

.containerLogo > img {
  width: 100%;
  padding: 1.2rem 4rem;
}

/* Clase Mostrar menu lateral */
.exitBtn {
  cursor: pointer;
  display: none;
}

/* Respnsive tablet */
@media (max-width: 750px) {
  .mainContainerActivated {
    left: 0 !important;
    width: 15rem !important;
  }

  .showMenu.backdrop {
    display: block;
  }

  .mainContainerDesactivated {
    left: -20rem !important;
  }

  .mainContainer {
    left: -20rem;
    overflow: hidden;
  }

  .containerLogo > img {
    width: 15rem;
    padding: 1.2rem 2rem;
    overflow: hidden;
  }

  .exitBtn {
    display: block;
  }
}

/* Respnsive tablet */
@media (max-width: 500px) {
  .mainContainerActivated {
    left: 0 !important;
    width: 13rem !important;
  }

  .containerLogo > img {
    width: 13rem;
    padding: 1.2rem 2rem;
    overflow: hidden;
  }
}

.backdrop {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9;
}
