.componentContainer {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  justify-content: flex-start;
}

.blackBackground {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.382);
  position: fixed;
  top: 0;
  left: 0;
}

.mainContainer {
  width: 70%;
  height: 90%;
  max-width: 60rem;
  align-self: center;
  background-color: whitesmoke;
  z-index: 4;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 5px;
  color: black;
  margin-left: 5rem;
  margin-right: 1rem;
  overflow: auto;
}

.mainContainer * {
  text-align: left;
  color: rgba(0, 0, 0, 0.688);
}

.mainContainer > header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.25rem 2rem;
  background: linear-gradient(90deg, #00cc88 0%, #7fffd4 40%);
  color: black;
  border-radius: 5px 5px 0 0;
}

.mainContainer > header h5 {
  margin: 0;
  font-size: 1.5rem;
  flex-grow: 1;
}

.mainContainer > header i {
  cursor: pointer;
  font-size: 35px;
}

.containerContent {
  padding: 1rem;
  overflow-y: auto;
}

.containerContent::-webkit-scrollbar {
  width: 12px;
}

.containerContent::-webkit-scrollbar-track {
  background-color: #d4d4d4;
  border-radius: 3px;
}

.containerContent::-webkit-scrollbar-thumb {
  background-color: #7fffd4;
  border-radius: 3px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.contianerVideo > video {
  width: 100%;
  min-height: 20rem;
  background-color: black;
}

.titlesRates {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 0;
}

.titlesRates > div > p > a {
  text-decoration: none;
  color: #5697e6;
}

.mainContainer > article {
  padding: 1rem;
}

.containerBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.containerBtns > button {
  padding: 0.5rem 1.5rem;
  border: 0;
  background-color: #7fffd4;
  color: rgba(0, 0, 0, 0.781);
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.15s ease;
}

.containerBtns > button:hover {
  background-color: rgba(127, 255, 212, 0.766);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.mainContainerRight {
  position: relative;
  width: 25rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.617);
  overflow-y: auto;
  display: block;
  margin-left: auto;
}

.mainContainerRight > header {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #2d3131;
}

.mainContainerRight > header > i {
  font-size: 30px;
  margin-right: 0.5rem;
}

.mainContainerRight > header > h5 {
  font-size: 1.5rem;
  font-weight: 400;
}

.mainContainerRight > article {
  padding: 1rem 1.5rem;
  background-color: #212424;
  border-left: 5px solid #00cc88;
  border-top: 3px solid rgb(85, 85, 85);
  color: white;
}

.mainContainerRight > article > div {
  display: flex;
  align-items: center;
}

.mainContainerRight > article > p {
  line-height: 1.5rem;
}

/* Responsive Design */

@media (max-width: 1000px) {
  .componentContainer {
    justify-content: center;
  }

  .mainContainer {
    width: 90%;
    margin: 1rem auto;
  }

  .mainContainerRight {
    display: none;
  }

  .containerContent {
    max-height: 20rem;
  }
}

@media (max-width: 700px) {
  .mainContainer {
    width: 95%;
    padding: 0.5rem;
  }

  .mainContainer > header h5 {
    font-size: 1rem;
  }

  .containerBtns > button {
    font-size: 0.85rem;
    padding: 0.4rem 1rem;
  }

  .contianerVideo > video {
    min-height: 15rem;
  }

  .titlesRates {
    flex-direction: column;
    gap: 0.5rem;
  }

  .titlesRates > div > p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .mainContainer > header {
    padding: 0.5rem;
  }

  .containerBtns {
    flex-direction: column;
    padding: 0.5rem;
  }

  .containerBtns > button {
    width: 100%;
    padding: 0.5rem;
  }

  .titlesRates {
    flex-direction: column;
    align-items: flex-start;
  }

  .containerContent {
    min-height: 30rem;
  }
}
