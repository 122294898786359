.reportModalButton {
  padding: 0.5rem 1.5rem;
  border: 0;
  background-color: #7fffd4;
  color: rgba(0, 0, 0, 0.781);
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.15s ease;
  width: 100%;
}

.reportModalButton:hover {
  background-color: rgba(127, 255, 212, 0.766);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
