.mainContainermapmenu {
  display: flex;
  flex-direction: column;
  width: 14rem;
  font-size: 1.15rem;
  font-weight: 300;
  position: fixed;
  z-index: 2;
  right: -11.5rem;
  top: 15%;
  transition: all ease-in-out 0.5s;
  height: 550px;
  background: url('https://mdbootstrap.com/img/Photos/Others/sidenav1.jpg');
  opacity: 50%;
  border-radius: 50px 0px 0px 50px;
}

.mainContainermapmenu::after {
  background-color: rgba(0, 0, 0, 0.807);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  z-index: -2;
  width: 100%;
  border-radius: 50px 0px 0px 40px;
}

.mainContainermapmenu:hover {
  right: 0;
  opacity: 100%;
}

.mainContainermapmenu a:link {
  color: transparent;
}

/* visited link */
.mainContainermapmenu a:visited {
  color: transparent;
}

/* mouse over link */
.mainContainermapmenu a:hover {
  color: transparent;
}

/* selected link */
.mainContainermapmenu a:active {
  color: transparent;
}

.mainContainermapmenu > header {
  /* display: flex; */
  /* flex-direction:row; */
  align-items: center;
  width: 100%;
  padding: 1.25rem 2rem;
  background-color: aquamarine;
  border-radius: 50px 0px 0px 0px;
}
.containerLogo {
  width: 100%;
  border-bottom: 1px solid rgba(194, 194, 194, 0.21);
}

.containerLogo > img {
  width: 100%;
  padding: 1.2rem 4rem;
}

/* Clase Mostrar menu lateral */

/* Respnsive tablet */
/* @media (max-width: 750px) {
  .mainContainermapmenu {
    left: -20rem;
  }
} */
