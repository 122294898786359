.leaflet-container {
  width: 100%;
  height: 85vh;
  overflow-y: hidden;
}
.leaflet-popup-content-wrapper {
  width: 200px; /* Define el ancho deseado para el Popup */
}
.map-popup-img {
  width: 50vh;
}
.map-icon {
  color: red;
  font-size: 30px;
}

.leaflet-top,
.leaflet-left {
  left: 47px;
  top: 68px;
}

@media (max-width: 768px) {
  .leaflet-top,
  .leaflet-left {
    left: 0px;
    top: 68px;
  }
}
